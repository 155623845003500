<template>
  <v-content style="padding-top: 55px !important;">
    <router-view />

<!--    <dashboard-core-footer />-->
  </v-content>
</template>

<script>
  export default {
    name: 'DashboardCoreView',

    components: {
      // DashboardCoreFooter: () => import('./Footer'),
    },
  }
</script>
